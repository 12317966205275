// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-forms-and-inputs-js": () => import("../src/pages/category/forms-and-inputs.js" /* webpackChunkName: "component---src-pages-category-forms-and-inputs-js" */),
  "component---src-pages-category-fundamentals-js": () => import("../src/pages/category/fundamentals.js" /* webpackChunkName: "component---src-pages-category-fundamentals-js" */),
  "component---src-pages-category-react-js": () => import("../src/pages/category/react.js" /* webpackChunkName: "component---src-pages-category-react-js" */),
  "component---src-pages-category-structure-and-layout-js": () => import("../src/pages/category/structure-and-layout.js" /* webpackChunkName: "component---src-pages-category-structure-and-layout-js" */),
  "component---src-pages-contribute-to-a-11-y-in-oss-js": () => import("../src/pages/contribute-to-a11y-in-oss.js" /* webpackChunkName: "component---src-pages-contribute-to-a-11-y-in-oss-js" */),
  "component---src-pages-handling-focus-solution-js": () => import("../src/pages/handling-focus-solution.js" /* webpackChunkName: "component---src-pages-handling-focus-solution-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-layouts-enhanced-js": () => import("../src/pages/page-layouts-enhanced.js" /* webpackChunkName: "component---src-pages-page-layouts-enhanced-js" */),
  "component---src-pages-page-layouts-reorganised-js": () => import("../src/pages/page-layouts-reorganised.js" /* webpackChunkName: "component---src-pages-page-layouts-reorganised-js" */),
  "component---src-pages-page-layouts-starter-js": () => import("../src/pages/page-layouts-starter.js" /* webpackChunkName: "component---src-pages-page-layouts-starter-js" */)
}

